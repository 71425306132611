import DataObject from 'o365.modules.DataObject.ts';
import { SignalRHandler } from 'o365.modules.SignalRHandler.js'

interface ILiveUpdateOptions {
    dataObjects?: DataObject[];
    key?: string;
    detailsId?: string;
}

interface ILiveUpdateMessage {
    id: string;
    primKey: string;
    state?: Object;
}

export default class LiveUpdate {
    private _key: string;
    private _dataObjects: Map<string, DataObject> = new Map();
    private _detailId?: string;
    private _signalR: SignalRHandler;

    constructor(options: ILiveUpdateOptions) {
        this._key = options.key?replaceInvalidSigns(options.key!)!: options.dataObjects![0].appId;

        if (options.detailsId) {
            this._detailId = options.detailsId;
        }

        for (const dataObject of options.dataObjects!) {
            this._dataObjects.set(dataObject.id, dataObject);
        }

        this._signalR = new SignalRHandler({
            //@ts-expect-error
            groupName: this._detailId ? `${this._key}-${this._detailId}` : `${this._key}`
        })

        this.connect();
        this.returnReceivers();
        this.initDataObjects();

    }

    getSignalR() {
        return this._signalR;
    }

    private async connect() {
        await this._signalR.connect();
    }

    // Change Receivers
    private returnReceivers() {
        this._signalR.on('update', (stateObj: ILiveUpdateMessage) => {
            if (this._dataObjects.has(stateObj.id)) {
                this._dataObjects.get(stateObj.id)?.recordSource.refreshRowByPrimKey(stateObj.primKey);
            } else {
                console.error("DataObject not found");
            }
        });

        this._signalR.on('create', (stateObj: ILiveUpdateMessage) => {
            if (this._dataObjects.has(stateObj.id)) {
                this._dataObjects.get(stateObj.id)?.recordSource.refreshRowByPrimKey(stateObj.primKey);
            } else {
                console.error("DataObject not found");
            }
        });

        this._signalR.on('delete', (stateObj: ILiveUpdateMessage) => {
            if (this._dataObjects.has(stateObj.id)) {
                const index = this._dataObjects.get(stateObj.id)?.storage.getItemByPrimKey(stateObj.primKey)?.index
                this._dataObjects.get(stateObj.id)?.remove(index);
            } else {
                console.error("DataObject not found");
            }
        });
    }

    // Change Senders
    private initDataObjects() {
        this._dataObjects.forEach((dataObject, id) => {
            dataObject.on("AfterSave", (pProps: any, pRow: any) => {
                if (this._signalR.priv.connection.state === "Connected") {
                    if (pProps.operation === 'update') {
                        this._signalR.sendMessage('update', this.createMessage(id, pProps.values.PrimKey, pProps.values))
                    }
                    if (pProps.operation === 'create') {
                        this._signalR.sendMessage('create',  this.createMessage(id, pRow.PrimKey))
                    }
                } else {
                    this._signalR.connect().then(() => {
                        if (pProps.operation === 'update') {
                            this._signalR.sendMessage('update', this.createMessage(id, pProps.values.PrimKey, pProps.values))
                        }
                        if (pProps.operation === 'create') {
                            this._signalR.sendMessage('create',  this.createMessage(id, pRow.PrimKey))
                        }
                    })
                }
                
            });
            dataObject.on("AfterDelete", (pProps: any) => {
                if (this._signalR.priv.connection.state === "Connected") {
                    this._signalR.sendMessage('delete', this.createMessage(id, pProps.values.PrimKey))        
                } else {
                    this._signalR.connect().then(() => {
                        this._signalR.sendMessage('delete', this.createMessage(id, pProps.values.PrimKey))        
                    })
                }
            })
        });
    }

    // Creating message object
    private createMessage(id: string, primKey: string, state?: Object): ILiveUpdateMessage {
        return {
            id: id,
            primKey: primKey,
            state: state
        }
    }

    // Method for sending 'delete' message
    sendDelete(dataObject: DataObject, state: any) {
        this._signalR.sendMessage('delete', this.createMessage(dataObject.id, state.PrimKey));
    }

    // Method for sending 'update' message
    sendUpdate(dataObject: DataObject, state: any) {
        this._signalR.sendMessage('update', this.createMessage(dataObject.id, state.PrimKey, state));
    }

    // Method for sending 'create' message
    sendCreate(dataObject: DataObject, state: any) {
        this._signalR.sendMessage('create', this.createMessage(dataObject.id, state.PrimKey));
    }
}

function replaceInvalidSigns(str: string) {
    const regex = /[?:!=]+/g
    str.replaceAll(regex, '')
}